div.home a {
  text-decoration: none;
}

div.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.years {
  font-size: 8vw;
  margin-bottom: 7vw;
}

div.call {
  padding: 2vw 5vw;
  width: 85vw;
  margin: 4vw 0vw;
  /* height: 16vw; */
  align-items: center;
  font-size: 6vw;
  border-radius: 20vw;
  letter-spacing: 0.3vw;
}
svg.phone {
  width: 1.6em;
}

div.number {
  margin-bottom: 0.4vw;
  margin-left: 9vw;
}

div.infowrap {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.info {
  padding: 2vw 10vw;
  width: 100vw;
  margin: 4vw 0vw;
  align-items: center;
  font-size: 4.5vw;
  font-weight: 500;
  letter-spacing: 0.3vw;
}

svg.pin {
  width: 2em;
  margin-right: 6vw;
}

svg.open {
  width: 1.6em;
}

/* ------------------------ */

div.img-wrap {
  position: relative;
  width: 90vw;
  margin-bottom: 7vw;
  height: 90vw;
}

div.img-wrap > div {
  top: 0vw;
  width: 90vw;
  height: 90vw;

  position: absolute;
  overflow: hidden;
}
.bottom {
  border-bottom-style: solid;
  border-bottom-color: white;
  border-bottom-width: 1px;
}

img.img1 {
  width: 90vw;
  height: 90vw;
  object-fit: cover;
}

img.img2 {
  width: 100vw;
  height: 100vw;
  margin-bottom: 30vw;
  object-fit: cover;
}

h1 {
  /* font-family: 'Rubik', sans-serif; */
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 14vw;
  margin: 0;
  padding: 0vw 2vw;
  text-transform: uppercase;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  width: 100vw;
}

h2 {
  /* font-family: 'Rubik', sans-serif; */
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 10vw;
  text-transform: lowercase;
  font-weight: 400;
  text-align: right;
  padding: 4vw 4vw;
  margin: 0;
}

.work-type {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.work-type > div {
  border-radius: 0.5em;
}

.work {
  margin-top: 10vw;
  padding-top: 10vw;
}
div.address {
  width: 55vw;
}

div.hours {
  text-transform: capitalize;
  width: 45vw;
}

div.open {
  text-transform: capitalize;
}

div.dot {
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  margin-right: 1em;
}
div.about {
  width: 100vw;
  font-size: 5.5vw;
  font-weight: 500;
  padding: 10vw;
}

div.about_info {
  margin-top: 0px;
  width: 100vw;
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
}

div.years2 {
  display: none;
  margin-top: -1vw;
}

@media only screen and (min-width: 860px) {
  div.intro {
    /* flex-direction: row-reverse; */
    /* align-items: flex-start; */
    /* height: 90vh; */
    padding-bottom: 200px;
  }

  div.about_info {
    margin-top: 3vw;
    padding: 0px 5vw;

    flex-direction: row;
  }
  div.about {
    width: 43vw;
    font-size: 2.26vw;
    font-weight: 500;
    padding: 0 0 0 5vw;
  }
  div.infowrap {
    width: 40vw;
  }
  div.aboutwrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div.years2 {
    display: block;
    font-size: 4vw;
    margin-bottom: 2vw;
    padding: 0 0 0 5vw;
  }

  div.call {
    padding: 0.6vw 2vw;
    margin: 0px;
    font-size: 2vw;
    width: 30vw;
    font-weight: 600;
  }

  div.number {
    margin-bottom: 0.3vw;
    margin-left: 3vw;
  }

  /* ------------------------ */

  div.desk {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  img.img3 {
    width: 25vw;
    height: 25vw;
    margin: 2vw;
    object-fit: cover;
    border-radius: 1vw;
  }
  /* div.img-wrap {
    position: relative;
    width: 50vw;
    margin-bottom: 0px;
    height: 50vw;
  }

  div.img-wrap > div {
    width: 40vw;
    height: 40vw;
  }
  img.img1 {
    width: 40vw;
    height: 40vw;
    object-fit: cover;
  } */

  div.info {
    padding: 0px;
    width: 30vw;
    margin: 0px;
    font-size: 1.7vw;
    margin-bottom: 3.5vw;
    letter-spacing: 0.1vw;
  }

  svg.pin {
    width: 4vw;
    margin-right: 2vw;
  }
  div.hours {
    width: 20vw;
  }

  div.address {
    width: 20vw;
  }
  svg.open {
    width: 3vw;
    margin-left: 1vw;
  }

  .work {
    margin-top: 0px;
    padding-top: 20px;
  }

  h1 {
    font-size: 8vw;
    margin: 0;
    padding: 0px 4vw;
  }

  h2 {
    font-size: 6vw;
    padding: 0px 4vw;
    margin: 0;
  }

  div.img2-wrap {
    width: 100vw;
    display: flex;
    justify-content: center;
  }

  img.img2 {
    width: 80vw;
    height: 80vw;
    margin-bottom: 300px;
    max-width: 800px;
    max-height: 800px;
  }
}
