header {
  width: 100vw;
  display: flex;
  align-items: center;
}

header svg.logo {
  width: 100vw;
}

div.town {
  display: none;
}
@media only screen and (min-width: 860px) {
  header {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5.5vw;
  }
  div.town {
    display: block;
    padding: 3vw;
    font-size: 3vw;
    text-transform: lowercase;
  }
  header svg.logo {
    width: 25vw;
    padding: 1vw;
  }
}
