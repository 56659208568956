@import url('https://fonts.googleapis.com/css2?family=Niconne&family=Oswald:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500;600;700;800&display=swap');
body {
  margin: 0 !important;
  padding: 0;
  overflow-x: hidden;
}
html,
body {
  position: relative;
}

.stack {
  display: flex;
}

.v {
  flex-direction: column;
}

.h {
  flex-direction: row;
}

.c {
  align-items: center;
  justify-content: center;
}

.oswald {
  font-family: 'Oswald', sans-serif;
}

.nicone {
  font-family: 'Niconne', cursive;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}
.barlow {
  font-family: 'Barlow Condensed', sans-serif;
}
